import React, { Component } from 'react';
import Tooltip from '../layouts/Tooltip'; 

class designSlide extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            array : []
        }

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        // const Arr = [];
        const values = {
            id : e.target.id,
            name : e.target.name,
            value : e.target.value,
            budget: e.target.budget,
            monthlyBudget: e.target.monthlyBudget
        }

        // console.log(e.target.check);
        
        if(e.target.check === true){
            if(e.target.type === 'radio'){
                let filteredArray = this.state.array.filter(item => item.name !== e.target.name)
                    filteredArray.push(values);
                this.setState({array: filteredArray});

                setTimeout(() => {
                    this.props.leads(this.state.array)
                }, 300);
            }else{
                this.state.array.push(values);
                this.props.leads(this.state.array)
            }
        }else{
            let filteredArray = this.state.array.filter(item => item.id !== e.target.id)
            this.setState({array: filteredArray});

            setTimeout(() => {
                this.props.leads(this.state.array)
            }, 300);
        }


    }

    render() {
        return (
            <div
                className="slide-part second-slide slick-slide"
            >
                <h2>What services are you interested in…</h2>
                <p className="sub-heading">(Design)</p>
                <div className="row">
                <div className="col-12 col-md-12">
                    <div className="form-group-area">
                    <div className="form-group">
                        <h5>
                        Would you want the website to be a
                        custom design vs Templated?{" "}
                        <span>(Select multiple)</span>
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="custom-design"
                            id="question9summary_custom-design"
                            value="Custom Design"
                            name="question9summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1250,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question9summary_custom-design"
                        >
                            Custom Design
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="custom-design"
                            id="question9summary_templated"
                            value="Templated"
                            name="question9summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Template Design",
                                        budget: 50,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question9summary_templated"
                        >
                            Templated
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <h5>Would you need a logo design?</h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question10summary_logo-design"
                            value="Yes"
                            name="question10summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Need logo design",
                                        budget: 500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question10summary_logo-design"
                        >
                            Yes
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <h5>
                        Would you require email builds and
                        how many?
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value1"
                            value="0 - 10"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "0 - 3 email builds",
                                        budget: 250,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value1"
                        >
                            0 - 3
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value2"
                            value="10 - 25"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: '4 - 7 email builds',
                                        budget: 500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value2"
                        >
                            4 - 7
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value3"
                            value="25 - 50"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: '8 - 12 email builds',
                                        budget: 750,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value3"
                        >
                            8 - 12
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value4"
                            value="50 - 100"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: '13 - 20 email builds',
                                        budget: 1200,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value4"
                        >
                            13 - 20
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value5"
                            value="100+"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: '20+ #  email builds',
                                        budget: 2500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value5"
                        >
                            20+
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="require-email-builds"
                            id="question11summary_value6"
                            value="I don’t know"
                            name="question11summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: 'Not sure about email builds',
                                        budget: 500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label radio-box"
                            htmlFor="question11summary_value6"
                        >
                            I don’t know
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="d-block">
                            <h5 className="d-inline-block position-relative">
                            Would you require marketing automation journeys &nbsp; 
                            <Tooltip text="Marketing automation is all about using software to automate marketing activities. Marketing departments automate repetitive tasks such as email marketing, social media posting, and even ad campaigns.">
                                (Read here)
                            </Tooltip>
                            ?
                            </h5>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question12summary_call-journey"
                            value="Request a call journey"
                            name="question12summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 250,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question12summary_call-journey"
                        >
                            Request a call journey
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question12summary_cart-journey"
                            value="Abandon cart journey"
                            name="question12summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 350,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question12summary_cart-journey"
                        >
                            Abandon cart journey
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question12summary_on-boarding-journey"
                            value="New customer on-boarding journey"
                            name="question12summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 350,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question12summary_on-boarding-journey"
                        >
                            New customer on-boarding journey
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="d-block">
                            <h5 className="d-inline-block position-relative">
                            Would you like the website to be fully optimised for SEO &nbsp; 
                            <Tooltip text="Search Engine Optimization is the process of improving the quality and quantity of website traffic to a website or a web page from search engines">
                                (Read here)
                            </Tooltip>
                            ?
                            </h5>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question13summary_fully-optimised"
                            value="Yes"
                            name="question13summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: 'Fully SEO Optimised',
                                        budget: 1000,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question13summary_fully-optimised"
                        >
                            Yes
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <h5>
                        Do you need any copywriting services
                        for the website /app ?
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question14summary_copywriting-services"
                            value="Yes"
                            name="question14summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: 'Need Copywriting Services',
                                        budget: 1000,
                                        monthlyBudget: 450
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question14summary_copywriting-services"
                        >
                            Yes
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}
export default designSlide;