import React, { Component } from 'react';
// import Recaptcha from 'react-recaptcha';
  import ReCAPTCHA from "react-google-recaptcha";



class contactForm extends Component {
  constructor(props) {
    super(props);
      this.state = {
        recaptch: ''
      }
  }


  // componentDidMount(){
  //   if (typeof window !== 'undefined') {
  //       function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } 
      
  //     this.interval = setInterval(timestamp, 500);
  //   }
  // }

  onChange = (value) => {
		// console.log("Captcha value:", value);
		this.setState({
			recaptch: value
		})
	}

  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  render() {
    let search = '';
    if (typeof window !== 'undefined') {
        search = window.location.search;
    }
    return (
      <>
        <form
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
            >
            <input type="hidden" name='captcha_settings' value='{"keyname":"Alkye","fallback":"true","orgId":"00D2x000000ptjv","ts":""}' />
                <input type="hidden" name="oid" value="00D2x000000ptjv" />
                <input type="hidden" name="retURL" id="retURL" value="https://alkye.com/get-a-quote?thankyou=yes" />
                <input type="hidden" name="00N2x000001bUFy" value="Alkye" />
                <input type="hidden" name="Campaign_ID" value="7012x0000001eFV" />
                <input type="hidden" name="lead_source" value="Website" />
                <input type="hidden" name="company" value="na" />

            <div className="form-group">
                <input
                type="text"
                placeholder="First name"
                className="form-control"
                name="first_name"
                id="first_name"
                required="required"
                />
            </div>

            <div className="form-group">
                <input
                type="text"
                placeholder="Last name"
                className="form-control"
                name="last_name"
                id="last_name"
                required="required"
                />
            </div>
            <div className="form-group">
                <input
                type="email"
                placeholder="Email"
                className="form-control"
                name="email"
                id="email"
                required="required"
                />
            </div>
            <div className="form-group">
                <input
                id="phone"
                maxlength="80"
                name="phone"
                size="20"
                type="tel"
                placeholder="Phone"
                className="form-control"
                />
            </div>
            <select
                id="00N2x000001bUFi"
                name="00N2x000001bUFi"
                className="form-control"
                required="required"
                hidden
            >
                <option value=""> -- Select country --</option>
                <option value="Afghanistan" selected>Get a Quote</option>
            </select>
            <textarea
                placeholder="Your Message"
                className="main-text-field"
                name="00N2x000001bUG8"
                id="00N2x000001bUG8"
                required="required"
                hidden
                value="Get a Quote"
                ></textarea>

                <div className="form-group">
                {/* <div className="g-recaptcha" data-sitekey="6Le52-gUAAAAANj4Rhvu2jLtsfsvIwxdyvjhqhk_"></div> */}
                <ReCAPTCHA
                  sitekey="6Le52-gUAAAAANj4Rhvu2jLtsfsvIwxdyvjhqhk_"
                  onChange={this.onChange}
                />
                </div>

                {this.state.recaptch ? (
                  <button
                    type="submit"
                    className="btn submit-button"
                >
                    Submit <i className="la la-arrow-right"></i>
                </button>
                ) : (
                  <button
                  type="button"
                  disabled
                  className="btn submit-button"
              >
                  Submit <i className="la la-arrow-right"></i>
              </button>
                )}
            </form>
            {search && search ? (
              <div className="text-center pt-2 text-success">
                Thank you for submitting your details, we'll be in touch shortly.
              </div>
            ) : (
              ""
            )}
      </>
    )
  }

}
  export default contactForm;