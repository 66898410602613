import React, { Component } from 'react';
import Tooltip from '../layouts/Tooltip'; 

class technologySlide extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            array : [],
            leadRemoveId : ''
        }

        // this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
        // const Arr = [];
        const values = {
            id : e.target.id,
            name : e.target.name,
            value : e.target.value,
            budget: e.target.budget,
            monthlyBudget: e.target.monthlyBudget
        }

        // console.log(e.target.check);
        
        if(e.target.check === true){
            if(e.target.type === 'radio'){
                let filteredArray = this.state.array.filter(item => item.name !== e.target.name)
                    filteredArray.push(values);
                this.setState({array: filteredArray});

                setTimeout(() => {
                    this.props.leads(this.state.array)
                }, 300);
            }else{
                this.state.array.push(values);
                this.props.leads(this.state.array)
            }
        }else{
            let filteredArray = this.state.array.filter(item => item.id !== e.target.id)
            this.setState({array: filteredArray});

            setTimeout(() => {
                this.props.leads(this.state.array)
            }, 300);
        }


    }

    

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.leadRemove !== this.props.leadRemove) {
    //         console.log('Prop Received: ', nextProps.leadRemove);

    //         let filteredArray = this.state.array.filter(item => item.id !== nextProps.leadRemove)
    //         this.setState({array: filteredArray, leadRemoveId:nextProps.leadRemove });

    //         // this.unCheck(nextProps.leadRemove);
    //         setTimeout(() => {
    //             this.props.leads(this.state.array);
    //         }, 500);
    //     }
    // }


    
    render() {
        // console.log(this.state)
        return (
            <div className="slide-part slick-slide">
                <h2>What services are you interested in…</h2>
                <p className="sub-heading">(Technology)</p>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="form-group-area">
                        <div className="form-group">
                            <h5>
                            What technologies are you after (
                            Select all that apply )?
                            </h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question1summary_ios-app"
                                value="iOS App"
                                name="question1summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        name: e.target.name,
                                        value: e.target.value,
                                        budget: 8000,
                                        monthlyBudget: 1000
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question1summary_ios-app"
                            >
                                iOS App
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question1summary_Android-app"
                                value="Android App"
                                name="question1summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        type: e.target.type,
                                        check: e.target.checked,
                                        name: e.target.name,
                                        value: e.target.value,
                                        budget: 8000,
                                        monthlyBudget: 1000
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question1summary_Android-app"
                            >
                                Android App
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question1summary_website"
                                value="Website"
                                name="question1summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        type: e.target.type,
                                        check: e.target.checked,
                                        name: e.target.name,
                                        value: e.target.value,
                                        budget: 1500,
                                        monthlyBudget: 350
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question1summary_website"
                            >
                                Website
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>
                            What functionalities are you after?{" "}
                            <span>(Select multiple)</span>
                            </h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_shop-ecomm"
                                value="Shop /Ecomm"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        type: e.target.type,
                                        check: e.target.checked,
                                        name: e.target.name,
                                        value: e.target.value,
                                        budget: 1250,
                                        monthlyBudget: 150
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_shop-ecomm"
                            >
                                Shop /Ecomm
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_donations"
                                value="Donations"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 750,
                                        monthlyBudget: 75
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_donations"
                            >
                                Donations
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_portfolio"
                                value="Portfolio"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 125,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_portfolio"
                            >
                                Portfolio
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_health-data-integration"
                                value="Health Data Integration (App only)"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Health Data",
                                        budget: 1500,
                                        monthlyBudget: 500
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_health-data-integration"
                            >
                                Health Data Integration (App only)
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_live-chat"
                                value="Live Chat"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 250,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_live-chat"
                            >
                                Live Chat
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_request-call-back"
                                value="Request a call back"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Request a call",
                                        budget: 250,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_request-call-back"
                            >
                                Request a call back
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_social-media"
                                value="Social Media"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 125,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_social-media"
                            >
                                Social Media
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_geo-tracking"
                                value="Geo-tracking (App only)"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Geo-tracking",
                                        budget: 750,
                                        monthlyBudget: 75
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_geo-tracking"
                            >
                                Geo-tracking (App only)
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_image-gallery"
                                value="Image Gallery"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 125,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}  
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_image-gallery"
                            >
                                Image Gallery
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_forum"
                                value="Forum"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 750,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_forum"
                            >
                                Forum
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_bookings"
                                value="Bookings"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1000,
                                        monthlyBudget: 100
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_bookings"
                            >
                                Bookings
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_push-notifications"
                                value="Push notifications (App only)"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Push notifications",
                                        budget: 1250,
                                        monthlyBudget: 250
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_push-notifications"
                            >
                                Push notifications (App only)
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_online-training"
                                value="Online Training"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Trainings",
                                        budget: 2000,
                                        monthlyBudget: 250
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_online-training"
                            >
                                Online Training
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_event-scheduling"
                                value="Event Scheduling"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Events",
                                        budget: 750,
                                        monthlyBudget: 150
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_event-scheduling"
                            >
                                Event Scheduling
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_video-library"
                                value="Video Library"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Videos",
                                        budget: 1250,
                                        monthlyBudget: 150
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_video-library"
                            >
                                Video Library
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_augmented-reality"
                                value="Augmented Reality (App only)"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Augmented Reality",
                                        budget: 3500,
                                        monthlyBudget: 350
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_augmented-reality"
                            >
                                Augmented Reality (App only)
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_web-notifications"
                                value="Web notifications"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 100
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_web-notifications"
                            >
                                Web notifications
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_media-publications"
                                value="Media &amp; Publications"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Media",
                                        budget: 500,
                                        monthlyBudget: 50
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_media-publications"
                            >
                                Media &amp; Publications
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question2summary_simple-blog"
                                value="Simple Blog"
                                name="question2summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Blog",
                                        budget: 125,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question2summary_simple-blog"
                            >
                                Simple Blog
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>Number of pages</h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_0-10"
                                value="0 - 10"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "0 - 10 pages",
                                        budget: 125,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_0-10"
                            >
                                0 - 10
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_10-25"
                                value="10 - 25"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "10 - 25 pages",
                                        budget: 350,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_10-25"
                            >
                                10 - 25
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_25-50"
                                value="25 - 50"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "25 - 50 pages",
                                        budget: 750,
                                        monthlyBudget: 50
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_25-50"
                            >
                                25 - 50
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_50-100"
                                value="50 - 100"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "50 - 100 pages",
                                        budget: 1000,
                                        monthlyBudget: 75
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_50-100"
                            >
                                50 - 100
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_100+"
                                value="100+"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "100+ # pages",
                                        budget: 2000,
                                        monthlyBudget: 2000
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_100+"
                            >
                                100+
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="pages-number"
                                id="question3summary_i-dont-know"
                                value="I don’t know"
                                name="question3summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Not sure about pages",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question3summary_i-dont-know"
                            >
                                I don’t know
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>
                            Would you require us to host your
                            website?
                            </h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="required-host"
                                id="question4summary_yes"
                                value="Yes"
                                name="question4summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Yes I need hosting",
                                        budget: 100,
                                        monthlyBudget: 100
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question4summary_yes"
                            >
                                Yes
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="required-host"
                                id="question4summary_own-hosting"
                                value="No, I have my own hosting"
                                name="question4summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "I have my hosting",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question4summary_own-hosting"
                            >
                                No, I have my own hosting
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>
                            Do you have any preferred technology
                            in mind?
                            </h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_alkye-recommends"
                                value="No, what ever Alkye recommends"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - Alkye Recommendation",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_alkye-recommends"
                            >
                                No, what ever Alkye recommends
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_wordpress"
                                value="WordPress"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - WordPress",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_wordpress"
                            >
                                WordPress
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_drupal"
                                value="Drupal"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - Drupal",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_drupal"
                            >
                                Drupal
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_joomla"
                                value="Joomla"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - Joomla",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_joomla"
                            >
                                Joomla
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_wix"
                                value="Wix"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - Wix",
                                        budget: 0,
                                        monthlyBudget: 0
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_wix"
                            >
                                Wix
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_react"
                                value="React"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - React",
                                        budget: 750,
                                        monthlyBudget: 150
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_react"
                            >
                                React
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology"
                                id="question5summary_angular"
                                value="Angular"
                                name="question5summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Technology - Angular",
                                        budget: 750,
                                        monthlyBudget: 150
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question5summary_angular"
                            >
                                Angular
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>Preferred Technology (App)</h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology-app"
                                id="question6summary_alkye-recommends-app"
                                value="No, what ever Alkye recommends"
                                name="question6summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "App - Alkye Recommendation",
                                        budget: 1000,
                                        monthlyBudget: 100
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question6summary_alkye-recommends-app"
                            >
                                No, what ever Alkye recommends
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology-app"
                                id="question6summary_skn-app"
                                value="Swift / Kotlin - Native App"
                                name="question6summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Native App",
                                        budget: 2000,
                                        monthlyBudget: 200
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question6summary_skn-app"
                            >
                                Swift / Kotlin - Native App
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology-app"
                                id="question6summary_reactnative"
                                value="Hybrid App - React Native"
                                name="question6summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "Hybrid App",
                                        budget: 250,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question6summary_reactnative"
                            >
                                Hybrid App - React Native
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preferred-technology-app"
                                id="question6summary_pwa"
                                value="PWA"
                                name="question6summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 100,
                                        monthlyBudget: 25
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label radio-box"
                                htmlFor="question6summary_pwa"
                            >
                                PWA
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="d-block">
                                <h5 className="d-inline-block position-relative">
                                Would you require user based
                                personalisation &nbsp; 
                                <Tooltip text="The main goal of personalization is to deliver content and functionality that matches specific user needs or interests, with no effort from the targeted users.">
                                    (Read here)
                                </Tooltip>
                                </h5>
                                ?
                            </div>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question7summary_user-based-personalisation"
                                value="Yes"
                                name="question7summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "I need user personalisation",
                                        budget: 1250,
                                        monthlyBudget: 200
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question7summary_user-based-personalisation"
                            >
                                Yes
                            </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5>
                            Would you require enhanced Tech and
                            Data security?
                            </h5>
                            <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="question8summary_data-security"
                                value="Yes"
                                name="question8summary"
                                onChange={(e) => {
                                    this.handleChange({
                                      target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: "I need advanced security",
                                        budget: 450,
                                        monthlyBudget: 50
                                      },
                                    });
                                }}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="question8summary_data-security"
                            >
                                Yes
                            </label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default technologySlide;