import React, { Component } from 'react';
import Tooltip from '../layouts/Tooltip'; 

class servicesSlide extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            array : [],
            customMonthlyBudget: 0
        }
    }

    handleChange = (e) => {
        // const Arr = [];
        const values = {
            id : e.target.id,
            name : e.target.name,
            value : e.target.value,
            budget: e.target.budget,
            monthlyBudget: e.target.monthlyBudget
        }

        // console.log(e.target.check);
        
        if(e.target.check === true){
            if(e.target.type === 'radio'){
                let filteredArray = this.state.array.filter(item => item.name !== e.target.name)
                    filteredArray.push(values);
                this.setState({array: filteredArray});

                setTimeout(() => {
                    this.props.leads(this.state.array, this.state.customMonthlyBudget)
                }, 300);
            }else{
                this.state.array.push(values);
                this.props.leads(this.state.array, this.state.customMonthlyBudget)
            }
        }
        else if(e.target.type === 'text'){
            // const valuestext = {
            //     id : e.target.id,
            //     name : e.target.name,
            //     value : e.target.value,
            //     budget: e.target.value || 1000,
            //     monthlyBudget: e.target.value || 1000
            // }

            let filteredArray = this.state.array.filter(item => item.id !== e.target.id)
                filteredArray.push(values);
            this.setState({array: filteredArray});

            setTimeout(() => {
                this.props.leads(this.state.array)
            }, 300);
        }
        else{
            let filteredArray = this.state.array.filter(item => item.id !== e.target.id)
            this.setState({array: filteredArray});

            setTimeout(() => {
                this.props.leads(this.state.array, this.state.customMonthlyBudget)
            }, 300);
        }


    }

    render() {
        return (
            <div
                className="slide-part second-slide slick-slide"
            >
                <h2>What services are you interested in…</h2>
                <p className="sub-heading">
                (Everything else..)
                </p>
                <div className="row">
                <div className="col-12 col-md-12">
                    <div className="form-group-area">
                    <div className="form-group">
                        <div className="d-block">
                            <h5 className="d-inline-block position-relative">
                            Would you want an ongoing social media engagement &nbsp; 
                            <Tooltip text="Social media engagement measures the public shares, likes and comments for an online business' social media efforts.">
                                (Read here)
                            </Tooltip>
                            ?
                            </h5>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question15summary_social-media-engagement"
                            value="Yes"
                            name="question15summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: 'Need Social Media Engagement',
                                        budget: 1000,
                                        monthlyBudget: 1000
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question15summary_social-media-engagement"
                        >
                            Yes
                        </label>
                        </div>
                    </div>
                    <div className="form-group input-field">
                        <h5>
                        Would you like us to manage your
                        digital advertising? (10% of media
                        spend)
                        </h5>
                        <input
                        type="text"
                        className="form-control"
                        id="question16summary_digital-advertising"
                        placeholder="Enter your monthly media budget"
                        name="question16summary"
                        onChange={(e) => {
                            this.handleChange({
                                target: {
                                    id: e.target.id,
                                    name: e.target.name,
                                    check: e.target.checked,
                                    type: e.target.type,
                                    value: 'Media Budget- $' + e.target.value,
                                    budget: e.target.value,
                                    monthlyBudget: e.target.value
                                },
                            });
                        }}
                        />
                    </div>
                    <div className="form-group">
                        <h5>
                        We offer multiple kinds of reports,
                        select the ones you would like?
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_End-to-End"
                            value="End-to-End Website Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_End-to-End"
                        >
                            End-to-End Website Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_sentiment-reporting"
                            value="Customer Sentiment Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 350,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_sentiment-reporting"
                        >
                            Customer Sentiment Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_ad-reporting"
                            value="Ad Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_ad-reporting"
                        >
                            Ad Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_sales-reporting"
                            value="Sales Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_sales-reporting"
                        >
                            Sales Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_lead-reporting"
                            value="Lead Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 50
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_lead-reporting"
                        >
                            Lead Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_content-reporting"
                            value="SEO &amp; Content Reporting"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 500,
                                        monthlyBudget: 350
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_content-reporting"
                        >
                            SEO &amp; Content Reporting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_data-modeling"
                            value="Machine Learning &amp; Data Modeling"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1250,
                                        monthlyBudget: 500
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_data-modeling"
                        >
                            Machine Learning &amp; Data
                            Modeling
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question17summary_full-package"
                            value="Full Package (Bit of everything)"
                            name="question17summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1000,
                                        monthlyBudget: 250
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question17summary_full-package"
                        >
                            Full Package (Bit of everything)
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <h5>
                        Our demand generation services,
                        select the ones you would like?
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question18summary_sales-training"
                            value="Sales Training"
                            name="question18summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 750,
                                        monthlyBudget: 500
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question18summary_sales-training"
                        >
                            Sales Training
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question18summary_list-building"
                            value="List Building"
                            name="question18summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1250,
                                        monthlyBudget: 1250
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question18summary_list-building"
                        >
                            List Building
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question18summary_data-extraction"
                            value="Data Extraction and Scraping"
                            name="question18summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 1500,
                                        monthlyBudget: 1500
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question18summary_data-extraction"
                        >
                            Data Extraction and Scraping
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question18summary_cold-calling"
                            value="Cold Calling 2.0"
                            name="question18summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 3000,
                                        monthlyBudget: 3000
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question18summary_cold-calling"
                        >
                            Cold Calling 2.0
                        </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <h5>
                        Our business strategy services,
                        select the ones you would like?
                        </h5>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question19summary_business-consulting"
                            value="Business Consulting"
                            name="question19summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 2500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question19summary_business-consulting"
                        >
                            Business Consulting
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question19summary_marketing-finance"
                            value="Marketing Finance"
                            name="question19summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 2500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question19summary_marketing-finance"
                        >
                            Marketing Finance
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question19summary_market-research"
                            value="Market Research"
                            name="question19summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 2500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question19summary_market-research"
                        >
                            Market Research
                        </label>
                        </div>
                        <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="question19summary_growth-plan"
                            value="Growth Plan"
                            name="question19summary"
                            onChange={(e) => {
                                this.handleChange({
                                    target: {
                                        id: e.target.id,
                                        name: e.target.name,
                                        check: e.target.checked,
                                        type: e.target.type,
                                        value: e.target.value,
                                        budget: 2500,
                                        monthlyBudget: 0
                                    },
                                });
                            }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="question19summary_growth-plan"
                        >
                            Growth Plan
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}
export default servicesSlide;