import React, { Component } from 'react';
import AOS from 'aos';
import { graphql } from 'gatsby';
// import Img from "gatsby-image";

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';
import GetaQuote from '../layouts/GetAQuote';

import "../assets/css/style-calc.css";

// slides
import SlideOne from '../budgetForm/technologySlide';
import SlideTwo from '../budgetForm/designSlide';
import SlideThree from '../budgetForm/servicesSlide';


class getAQuote extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      summaryBox: false,
      currentStep : 1,
      technology : [],
      design : [],
      services : [],
      customMonthlyBudget: 0,
      moveStep5 : false,

      removeItem : '',

      oneTimeCost: 25000,
      monthlyCost: 2500
     }

     this.getTechnologyArr = this.getTechnologyArr.bind(this);
     this.getDesignArr = this.getDesignArr.bind(this);
     this.getServicesArr = this.getServicesArr.bind(this);
     this.toggleClassBar = this.toggleClassBar.bind(this);
     this.removeItem = this.removeItem.bind(this);
  }

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1? 1: currentStep - 1;
    this.setState({
      currentStep: currentStep
    })
  }

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 3? 4: currentStep + 1;
    this.setState({
      currentStep: currentStep
    })
  }

  _submit = () => {
    this.setState({
      currentStep: 5
    });

    var getTechnologyBudget = [];
    var getDesignBudget = [];
    var getServicesBudget = [];

    var getTechnologyMonthlyBudget = [];
    var getDesignMonthlyBudget = [];
    var getServicesMonthlyBudget = [];

    this.state.technology.map((list) => {
      getTechnologyBudget.push(list.budget);
      getTechnologyMonthlyBudget.push(list.monthlyBudget);
    });
    this.state.design.map((list) => {
      getDesignBudget.push(list.budget);
      getDesignMonthlyBudget.push(list.monthlyBudget);
    });
    this.state.services.map((list) => {
      getServicesBudget.push(list.budget);
      getServicesMonthlyBudget.push(list.monthlyBudget);
    });

    var oneTimeBudget = parseFloat(this._getArraySum(getTechnologyBudget)) + parseFloat(this._getArraySum(getDesignBudget)) + parseFloat(this._getArraySum(getServicesBudget));
    var MonthlyBudget = parseFloat(this._getArraySum(getTechnologyMonthlyBudget)) + parseFloat(this._getArraySum(getDesignMonthlyBudget)) + parseFloat(this._getArraySum(getServicesMonthlyBudget));
    
    this.setState({
      oneTimeCost : oneTimeBudget > 0 ? oneTimeBudget : this.state.oneTimeCost,
      monthlyCost : MonthlyBudget > 0 ? MonthlyBudget : this.state.monthlyCost
    })

  }

  _getArraySum = (a) => {
    var total=0;
    for(var i in a) { 
        total += a[i];
    }
    return total;
  }

  previousBtn(){
    let currentStep = this.state.currentStep;
    if(currentStep > 1){
      return(
        <button
          type="button"
          className="prev slick-arrow float-left"
          style={{ display: "inline-block" }}
          onClick={() => this._prev()}
        >
          <span>&lt;</span>
          Previous
        </button>
      )
    }
    return null;
  }
  nextBtn(){
    let currentStep = this.state.currentStep;
    if(currentStep < 3){
      return(
        <button
          type="button"
          className="next slick-arrow float-right"
          onClick={() => this._next()}
        >
          Next <span>&gt;</span>
        </button>
      )
    }
    return(
      <button
        type="button"
        onClick={() => this._submit()}
        className="submit slick-arrow float-right"
      >
        Submit
      </button>
    )
  }

  

  // Technology value
  getTechnologyArr = (value) => {
    this.setState({ technology: value });
  }

  // Design value
  getDesignArr = (value) => {
    this.setState({ design: value });
  }

  // Services value
  getServicesArr = (value, customBudget) => {
    this.setState({ services: value, customMonthlyBudget: customBudget });
  }

  toggleClassBar(){
    this.setState({
      summaryBox : !this.state.summaryBox
    })
  }

  removeItem = (id) => {
    this.setState({removeItem: id})
  }

  componentDidMount(){
    if(window.location.search){
      this.setState({
        currentStep: 5
      });
    }
  }

  render() {
    // let search = '';
    if (typeof window !== 'undefined') {
      AOS.init({ duration : 800});
      // search = window.location.search;
    }

    let currentStep = this.state.currentStep;
    // console.log(this.state.technology);
    const page = this.props.data.wordpressPage;

		return (
      <PrimaryLayout>
        <SEO 
          title={page.acf.seo_title}
          description={page.acf.seo_description}
          keywords={page.acf.seo_keywords}
        />
        <div className="container-fluid">
          <div className="row p-0 m-0">
            <div className="container main-container-padding-t-5 main-container-padding-b-2">
              <div className="row">
                <div className="col-md-6">
                  <div className="header-text-container">
                      <div data-aos="fade-up" className="vertical-center" dangerouslySetInnerHTML={{__html: page.content}} />
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    data-src={page.featured_media && page.featured_media.source_url}
                    className="header-image lazyload"
                    alt="Get a quote | Digital Marketing Agency | Alkye | Australia"
                  />
                  {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid primary-color-bg">
          {/* {this.state.currentStep} */}
          {this.state.currentStep < 4 ? (
            <div className="container">
              <div className="row align-items-start">
                <div className="col-12 col-md-8">
                  <div className="slider-outer">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="slider slider-area slick-initialized slick-slider">
                            <div aria-live="polite" className="slick-list slick-list-getaquote">
                              <div className="slick-track">
                                {currentStep === 1 ? (
                                  <SlideOne leads={this.getTechnologyArr} leadRemove={this.state.removeItem} />
                                ) : null}
                                {currentStep === 2 ? (
                                  <SlideTwo leads={this.getDesignArr} />
                                ) : null}
                                {currentStep === 3 ? (
                                  <SlideThree leads={this.getServicesArr} />
                                ) : null}
                                {/* {currentStep === 4 ? <Step04 /> : null } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dots-outer-box">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 slideControls">
                            <ul className="slick-dots">
                              <li className="slick-active">
                                <a href="/">{currentStep}</a>/3
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="arrow-outer-box">
                      <div className="container pb-5">
                        <div className="row">
                          <div className="col-12">
                            {this.previousBtn()}
                            {this.nextBtn()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-12 col-md-4 sticky-top pt-5 ${this.state.technology.length > 0 ? null : 'd-none'}`} style={{marginBottom: '105px'}}>
                  <div className={`summary-box ${this.state.summaryBox ? 'added-height' : ''}`}  onClick={this.toggleClassBar} id="summary-box-values">
                    <h2>Summary</h2>
                    <ul className="summary-list" id="question1summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question1summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id} onClick={() => this.removeItem(list.id)}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question2summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question2summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question3summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question3summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question4summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question4summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question5summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question5summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question6summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question6summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question7summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question7summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question8summary">
                      {this.state &&
                        this.state.technology
                          .filter((u) => u.name === "question8summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question9summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question9summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question10summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question10summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question11summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question11summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question12summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question12summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question13summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question13summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question14summary">
                      {this.state &&
                        this.state.design
                          .filter((u) => u.name === "question14summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question15summary">
                      {this.state &&
                        this.state.services
                          .filter((u) => u.name === "question15summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question16summary">
                      {this.state &&
                        this.state.services
                          .filter((u) => u.name === "question16summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question17summary">
                      {this.state &&
                        this.state.services
                          .filter((u) => u.name === "question17summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question18summary">
                      {this.state &&
                        this.state.services
                          .filter((u) => u.name === "question18summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                    <ul className="summary-list" id="question19summary">
                      {this.state &&
                        this.state.services
                          .filter((u) => u.name === "question19summary")
                          .map((list, index) => (
                            <li id={list.id} key={index}>
                              {list.value}{" "}
                              <span className="close-icon" id={list.id}></span>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <section className="result-area" style={{ paddingBottom: '0px' }}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2>Here is what we think..</h2>
                    <p className="sub-heading">
                      Please see our estimate below…
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-8">
                    <div className="result-box" style={{ paddingBottom: '0px', border: 'none' }}>
                      <div className="one-time-cost">
                        <h4>One time costs</h4>
                        <div className="cost-box">
                          <p id="lowTotalEstimate">
                            <span>Low</span>
                            <br />
                            <sup>$</sup>{this.state.oneTimeCost.toLocaleString()}
                          </p>
                          <p className="divider-line">/</p>
                          <p id="highTotalEstimate">
                            <span>High</span>
                            <br />
                            <sup>$</sup>{(this.state.oneTimeCost * 1.56).toLocaleString()}
                          </p>
                        </div>
                      </div>
                      <div className="monthly-cost">
                        <h4>Monthly costs</h4>
                        <div className="cost-box">
                          <p id="lowTotalMonthlyEstimate">
                            <span>Low</span>
                            <br />
                            <sup>$</sup>{this.state.monthlyCost.toLocaleString()}
                          </p>
                          <p className="divider-line">/</p>
                          <p id="highTotalMonthlyEstimate">
                            <span>High</span>
                            <br />
                            <sup>$</sup>{(this.state.monthlyCost * 1.56).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-12 col-md-4 ${this.state.technology.length > 0 ? null : 'd-none'}`}>
                    <div className={`summary-box ${this.state.summaryBox ? 'added-height' : ''}`} onClick={this.toggleClassBar}>
                      <h2>Summary</h2>
                      <ul className="summary-list" id="question1summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question1summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question2summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question2summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question3summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question3summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question4summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question4summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question5summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question5summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question6summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question6summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question7summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question7summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question8summary-result">
                        {this.state &&
                          this.state.technology
                            .filter((u) => u.name === "question8summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul className="summary-list" id="question9summary-result">
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question9summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question10summary-result"
                      >
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question10summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question11summary-result"
                      >
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question11summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question12summary-result"
                      >
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question12summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question13summary-result"
                      >
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question13summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question14summary-result"
                      >
                        {this.state &&
                          this.state.design
                            .filter((u) => u.name === "question14summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question15summary-result"
                      >
                        {this.state &&
                          this.state.services
                            .filter((u) => u.name === "question15summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question16summary-result"
                      >
                        {this.state &&
                          this.state.services
                            .filter((u) => u.name === "question16summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question17summary-result"
                      >
                        {this.state &&
                          this.state.services
                            .filter((u) => u.name === "question17summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question18summary-result"
                      >
                        {this.state &&
                          this.state.services
                            .filter((u) => u.name === "question18summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                      <ul
                        className="summary-list"
                        id="question19summary-result"
                      >
                        {this.state &&
                          this.state.services
                            .filter((u) => u.name === "question19summary")
                            .map((list, index) => <li id={list.id} key={index}>{list.value}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <div className="result-area" style={{ paddingTop: '0px' }}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="result-box" style={{ padding: '0px 0 20px' }}>
                    <p className="contact-note">
                      Please note: above prices are purely estimates. If you
                      would like to get a personalised quote please reach
                      out to us below.
                    </p>
                  </div>
                  <div className="contact-team-area" style={{ paddingTop: '20px' }}>
                    <div className="row">
                      <div className="col-12">
                        <div className="row contact-team-row">
                          <div className="col-lg-6 col-12 pt-2">
                            <div className="card-box">
                              <img
                                className="card-img lazyload"
                                data-src="https://storage.googleapis.com/alkye.com/1/2020/06/nicola-björk.png"
                                alt="Nicola Björk"
                              />
                              <div className="card-content">
                                <h5 className="card-title">Nicola Björk</h5>
                                <p className="card-text">
                                  Global Sales Lead <br /> +61 - 0481 717 454
                                </p>
                              </div>
                            </div>
                            {/* <div className="card-box">
                              <img
                                className="card-img lazyload"
                                data-src="https://storage.googleapis.com/alkye.com/1/2020/06/karan-singh-thakur.png"
                                alt="Karan Singh Thakur"
                              />
                              <div className="card-content">
                                <h5 className="card-title">
                                  Karan Singh Thakur
                                </h5>
                                <p className="card-text">
                                  Head of Sales, India <br /> +91-77602 01843
                                </p>
                              </div>
                            </div> */}
                          </div>
                          <div className="col-lg-6 col-12 pt-2">
                            <GetaQuote />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container pb-5">
            {search && search ? (
              <div className="text-success pt-3">
                Thank you for submitting your details, we'll be in touch shortly.
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
      </PrimaryLayout>
    );
	}
}

export default getAQuote;


export const query = graphql`{
  wordpressPage(wordpress_id: {eq: 894}) {
    content
    featured_media {
      source_url
    }
    acf{
      seo_title
      seo_keywords
      seo_description
    }
  }
}`
